<template>
  <div class="mobile-home">
    <div class="mobile-home-header">
      <img src="../../../assets/mobile_images/mo_hispital_operate_bg.png" alt="" />
      <!-- <div class="mobile-header-btn">进一步了解</div> -->
    </div>

    <!-- 体系集成服务 -->
    <mobileTitle
      titleZh="体系集成服务"
      titleEn="传世做为第三方解决方案提供商，为业主方提供整体运营解决方案"
      fontSizeEn=".28rem"
      marginTopZh=".62rem"
      marginBottomZh=".64rem"
      widthEn="5.32rem"
      lineEn=".5rem"
      marginTopEn=".32rem"
    ></mobileTitle>
    <div class="mobile-txjc-box">
      <div class="mobile-txjc-list">
        <img src="../../../assets/mobile_images/mo_hispital_operate_txjc1.png" alt="" />
        <img src="../../../assets/mobile_images/mo_hispital_operate_txjc2.png" alt="" />
      </div>
      <div class="mobile-txjc-list">
        <img src="../../../assets/mobile_images/mo_hispital_operate_txjc3.png" alt="" />
        <img src="../../../assets/mobile_images/mo_hispital_operate_txjc4.png" alt="" />
      </div>
      <img class="last-img" src="../../../assets/mobile_images/mo_hispital_operate_txjc5.png" alt="" />
    </div>
    <!-- 院内运营管理模式 -->
    <div class="mobile-grey-box">
      <mobileTitle titleZh="院内运营管理模式" marginTopZh=".64rem" marginBottomZh=".8rem"></mobileTitle>
      <div class="model-list">
        <img src="../../../assets/mobile_images/mo_hispital_operate_ynyy_a1.png" alt="" />
        <div class="model-txt">
          <div class="model-title">共管模式</div>
          <p>改造，信息化上线，设备维护，院内配送传世院内运营团队与医院采用共管模式。</p>
        </div>
      </div>
      <div class="model-list">
        <img src="../../../assets/mobile_images/mo_hispital_operate_ynyy_a2.png" alt="" />
        <div class="model-txt">
          <div class="model-title">驻场服务</div>
          <p>传世安排运营团队驻场服务，提供从库房等全方位提供管理服务。</p>
        </div>
      </div>
      <div class="model-list">
        <img src="../../../assets/mobile_images/mo_hispital_operate_ynyy_a3.png" alt="" />
        <div class="model-txt">
          <div class="model-title">效率提升</div>
          <p>提升院内管理效率的同时，将医护人员还给患者。</p>
        </div>
      </div>
      <img class="model-img" src="../../../assets/mobile_images/mo_hispital_operate_ynyy_a4.png" alt="" />
    </div>
    <!-- 院内运营管理示例 -->
    <mobileTitle titleZh="院内运营管理示例" marginTopZh=".64rem" marginBottomZh=".8rem"></mobileTitle>
    <div class="model-two-box">
      <div class="model-two-list">
        <img src="../../../assets/mobile_images/mo_hispital_operate_ynyy_b1.png" alt="" />
        <div class="model-two-txt">
          <div class="model-two-title">驻场服务</div>
          <p>验收入库、上架、捡货出库等库内操作</p>
          <p>库内养护、批号效期管理</p>
          <p>定期盘点 、物品的标准化</p>
          <p>协助库内管理以及多级附码工作</p>
        </div>
      </div>
      <div class="model-two-list">
        <img src="../../../assets/mobile_images/mo_hispital_operate_ynyy_b2.png" alt="" />
        <div class="model-two-txt">
          <div class="model-two-title">物品配送管理</div>
          <p>院内各用药点配送</p>
          <p>配送路线合理化分析</p>
          <p>排班和值班紧贴医院服务要求</p>
          <p>人员配置合理化</p>
        </div>
      </div>
      <div class="model-two-list">
        <img src="../../../assets/mobile_images/mo_hispital_operate_ynyy_b3.png" alt="" />
        <div class="model-two-txt">
          <div class="model-two-title">物流决策优化</div>
          <p>进行现状问题点分析与改善提案</p>
          <p>成本分析 、适量采购分析</p>
          <p>保证大库和各消耗点有合适的库存，消灭长期积压库存</p>
          <p>防止结算误差及其分析</p>
        </div>
      </div>
      <div class="model-two-list">
        <img src="../../../assets/mobile_images/mo_hispital_operate_ynyy_b4.png" alt="" />
        <div class="model-two-txt">
          <div class="model-two-title">定数管理</div>
          <p>定数设置、定数包加工</p>
          <p>定数卡片定期回收</p>
          <p>进行定数及业务流的调整</p>
          <p>部门、品类的消耗管理</p>
          <p>定数与消费实际情况的平衡分析</p>
        </div>
      </div>
    </div>
    <!-- 可视化运营 -->
    <mobileTitle titleZh="可视化运营" marginTopZh=".24rem" marginBottomZh=".6rem"></mobileTitle>
    <mobileImg :url="kshyyImg" boxHeight="6.1rem" marginBox=".4rem" ></mobileImg>
    <!-- <div class="ksh-img-box">
      <img src="../../../assets/mobile_images/mo_hispital_operate_kshyy1.png" alt="">
    </div> -->
    <img class="w100f" src="../../../assets/mobile_images/mo_hispital_operate_kshyy2.png" alt="">
  </div>
</template>
<script>
import mobileTitle from '@/components/title/mobile_title.vue'; //标题组件
import mobileImg from '@/components/img/mobile_img.vue'; //图片组件
export default {
  components: {
    mobileTitle,
    mobileImg
  },
  data() {
    return {
      kshyyImg:require('../../../assets/mobile_images/mo_hispital_operate_kshyy1.png')
    };
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.mobile-home {
  margin: 0 auto;
  .mobile-home-header {
    position: relative;
    min-height: 5rem;
    img {
      width: 100%;
    }
    .mobile-header-btn {
      position: absolute;
      left: 50%;
      bottom: 1.8rem;
      transform: translateX(-50%);
      width: 2.4rem;
      height: 0.72rem;
      line-height: 0.72rem;
      font-size: 0.28rem;
      text-align: center;
      color: #fff;
      background: #4fa1a4;
      border-radius: 0.04rem;
    }
  }
  .mobile-txjc-box {
    padding: 0 0.8rem 1.04rem 0.8rem;
    box-sizing: border-box;
    img {
      width: 2.4rem;
    }
    .last-img {
      margin-top: 0.4rem;
    }
    .mobile-txjc-list {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.36rem;
    }
  }
//   院内运营管理模式
  .mobile-grey-box {
    padding: 0.4rem;
    background: #fafafa;
    box-sizing: border-box;
    text-align: center;
    .model-list {
      display: flex;
      align-items: center;
      text-align: left;
      margin-bottom: 0.38rem;
      img {
        width: 0.64rem;
        height: 0.64rem;
        flex-shrink: 0;
        margin-right: 0.4rem;
      }
      .model-txt {
        .model-title {
          font-size: 0.32rem;
          color: #3c3c3c;
          font-weight: 500;
          margin-bottom: 0.1rem;
        }
        p {
          font-size: 0.28rem;
          color: #808080;
          line-height: 0.5rem;
        }
      }
    }
    .model-img {
      width: 5.52rem;
      margin: 0.48rem 0;
    }
  }
//   院内运营管理示例
  .model-two-box {
    padding: 0.4rem;
    box-sizing: border-box;
    .model-two-list {
      display: flex;
    //   align-items: center;
      text-align: left;
      margin-bottom: 0.38rem;
      img {
        width: 0.96rem;
        height: 0.96rem;
        flex-shrink: 0;
        margin-right: 0.4rem;
      }
      .model-two-txt {
        color: #3d3d3d;
        .model-two-title {
          font-size: 0.32rem;
          font-weight: 500;
          margin-bottom: 0.16rem;
        }
        p {
          width: 4.68rem;
          font-size: 0.24rem;
          line-height: 0.4rem;
        }
      }
    }
  }
  //可视化
  .ksh-img-box{
    margin: .4rem;
    box-sizing: border-box;
    overflow-x: auto;
    overflow-y: hidden;
    img{
        height: 6.1rem;
    }
  }
}
</style>
