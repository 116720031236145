var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home-block"},[_c('div',{staticClass:"home"},[_vm._m(0),_c('div',{staticClass:"main-contain"},[_c('Title',{attrs:{"titleZh":"体系集成服务","titleEn":"传世做为第三方解决方案提供商，为业主方提供整体运营解决方案","fontSizeEn":"16px","marginTopEn":"10px"}}),_c('img',{attrs:{"width":"1100","src":require("../../assets/images/hospital_operate_tx.png"),"alt":""}}),_c('div',{staticClass:"h125"})],1),_c('div',{staticClass:"bg-wrap"},[_c('Title',{attrs:{"titleZh":"运营服务内容","marginTopZh":"0px","colorZh":"#fff","marginBottomZh":"66px"}}),_c('div',{staticClass:"bg-box"},[_c('div',{staticClass:"bg-title-box"},[_c('div',{class:_vm.tabIndex == 0 ? 'bg-title-list bg-title-list-active' : 'bg-title-list ',on:{"click":function($event){return _vm.tabClick(0)}}},[_vm._v(" 院内运营管理模式 ")]),_c('div',{class:_vm.tabIndex == 1 ? 'bg-title-list bg-title-list-active' : 'bg-title-list ',on:{"click":function($event){return _vm.tabClick(1)}}},[_vm._v(" 院内运营管理示例 ")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.tabIndex == 0),expression:"tabIndex == 0"}],staticClass:"bg-content-box"},[_vm._m(1),_c('img',{staticClass:"content-left-img",attrs:{"src":require("../../assets/images/hospital_operate_b4.png"),"alt":""}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.tabIndex == 1),expression:"tabIndex == 1"}],staticClass:"bg-content-two"},[_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)])])],1),_c('div',{staticClass:"main-contain"},[_c('Title',{attrs:{"titleZh":"可视化运营"}}),_c('img',{staticClass:"w90f",attrs:{"src":require("../../assets/images/hospital_operate_block3.png"),"alt":""}}),_c('div',{staticClass:"h60"}),_c('img',{staticClass:"w75f",attrs:{"src":require("../../assets/images/hospital_operate_block4.png"),"alt":""}}),_c('div',{staticClass:"h110"})],1)]),_c('mobilePage')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home-header"},[_c('img',{staticClass:"w100f",attrs:{"src":require("../../assets/images/hospital_operate_bg.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-content-left"},[_c('div',{staticClass:"content-left-list"},[_c('img',{attrs:{"src":require("../../assets/images/hospital_operate_b1.png"),"alt":""}}),_c('div',{staticClass:"left-txt"},[_c('div',{staticClass:"left-title"},[_vm._v("共管模式")]),_c('p',[_vm._v("改造，信息化上线，设备维护，院内配送传世院内运营团队与医院采用共管模式。")])])]),_c('div',{staticClass:"content-left-list"},[_c('img',{attrs:{"src":require("../../assets/images/hospital_operate_b2.png"),"alt":""}}),_c('div',{staticClass:"left-txt"},[_c('div',{staticClass:"left-title"},[_vm._v("驻场服务")]),_c('p',[_vm._v("传世安排运营团队驻场服务，提供从库房等全方位提供管理服务。")])])]),_c('div',{staticClass:"content-left-list"},[_c('img',{attrs:{"src":require("../../assets/images/hospital_operate_b3.png"),"alt":""}}),_c('div',{staticClass:"left-txt"},[_c('div',{staticClass:"left-title"},[_vm._v("效率提升")]),_c('p',[_vm._v("提升院内管理效率的同时，将医护人员还给患者。")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-two-list"},[_c('img',{attrs:{"src":require("../../assets/images/hospital_operate_c1.png"),"alt":""}}),_c('div',{staticClass:"left-txt"},[_c('div',{staticClass:"left-title"},[_vm._v("驻场服务")]),_c('p',[_vm._v("验收入库、上架、捡货出库等库内操作")]),_c('p',[_vm._v("库内养护、批号效期管理")]),_c('p',[_vm._v("定期盘点 、物品的标准化")]),_c('p',[_vm._v("协助库内管理以及多级附码工作")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-two-list"},[_c('img',{attrs:{"src":require("../../assets/images/hospital_operate_c2.png"),"alt":""}}),_c('div',{staticClass:"left-txt"},[_c('div',{staticClass:"left-title"},[_vm._v("物品配送管理")]),_c('p',[_vm._v("院内各用药点配送")]),_c('p',[_vm._v("配送路线合理化分析")]),_c('p',[_vm._v("排班和值班紧贴医院服务要求")]),_c('p',[_vm._v("人员配置合理化")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-two-list"},[_c('img',{attrs:{"src":require("../../assets/images/hospital_operate_c3.png"),"alt":""}}),_c('div',{staticClass:"left-txt"},[_c('div',{staticClass:"left-title"},[_vm._v("物流决策优化")]),_c('p',[_vm._v("进行现状问题点分析与改善提案 ")]),_c('p',[_vm._v("成本分析 、适量采购分析")]),_c('p',[_vm._v("保证大库和各消耗点有合适的库存，消灭长期积压库存")]),_c('p',[_vm._v("防止结算误差及其分析")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-two-list"},[_c('img',{attrs:{"src":require("../../assets/images/hospital_operate_c4.png"),"alt":""}}),_c('div',{staticClass:"left-txt"},[_c('div',{staticClass:"left-title"},[_vm._v("定数管理")]),_c('p',[_vm._v("定数设置、定数包加工")]),_c('p',[_vm._v("定数卡片定期回收")]),_c('p',[_vm._v("进行定数及业务流的调整")]),_c('p',[_vm._v("部门、品类的消耗管理")]),_c('p',[_vm._v("定数与消费实际情况的平衡分析")])])])
}]

export { render, staticRenderFns }