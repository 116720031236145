<template>
  <div class="home-block">
    <div class="home">
      <!-- 头部开始 -->
      <div class="home-header">
        <img class="w100f" src="../../assets/images/hospital_operate_bg.jpg" alt="" />
      </div>
      <!-- 头部结束 -->
      <!-- 中间内容开始 -->
      <div class="main-contain">
        <Title
          titleZh="体系集成服务"
          titleEn="传世做为第三方解决方案提供商，为业主方提供整体运营解决方案"
          fontSizeEn="16px"
          marginTopEn="10px"
        ></Title>
        <img width="1100" src="../../assets/images/hospital_operate_tx.png" alt="" />
        <div class="h125"></div>
      </div>
      <div class="bg-wrap">
        <Title titleZh="运营服务内容" marginTopZh="0px" colorZh="#fff" marginBottomZh="66px"></Title>
        <div class="bg-box">
          <div class="bg-title-box">
            <div :class="tabIndex == 0 ? 'bg-title-list bg-title-list-active' : 'bg-title-list '" @click="tabClick(0)">
              院内运营管理模式
            </div>
            <div :class="tabIndex == 1 ? 'bg-title-list bg-title-list-active' : 'bg-title-list '" @click="tabClick(1)">
              院内运营管理示例
            </div>
          </div>
          <div class="bg-content-box" v-show="tabIndex == 0">
            <div class="bg-content-left">
              <div class="content-left-list">
                <img src="../../assets/images/hospital_operate_b1.png" alt="" />
                <div class="left-txt">
                  <div class="left-title">共管模式</div>
                  <p>改造，信息化上线，设备维护，院内配送传世院内运营团队与医院采用共管模式。</p>
                </div>
              </div>
              <div class="content-left-list">
                <img src="../../assets/images/hospital_operate_b2.png" alt="" />
                <div class="left-txt">
                  <div class="left-title">驻场服务</div>
                  <p>传世安排运营团队驻场服务，提供从库房等全方位提供管理服务。</p>
                </div>
              </div>
              <div class="content-left-list">
                <img src="../../assets/images/hospital_operate_b3.png" alt="" />
                <div class="left-txt">
                  <div class="left-title">效率提升</div>
                  <p>提升院内管理效率的同时，将医护人员还给患者。</p>
                </div>
              </div>
            </div>
            <img class="content-left-img" src="../../assets/images/hospital_operate_b4.png" alt="" />
          </div>
          <div class="bg-content-two" v-show="tabIndex == 1">
            <div class="content-two-list">
              <img src="../../assets/images/hospital_operate_c1.png" alt="" />
              <div class="left-txt">
                <div class="left-title">驻场服务</div>
                <p>验收入库、上架、捡货出库等库内操作</p>
                <p>库内养护、批号效期管理</p>
                <p>定期盘点 、物品的标准化</p>
                <p>协助库内管理以及多级附码工作</p>
              </div>
            </div>
            <div class="content-two-list">
              <img src="../../assets/images/hospital_operate_c2.png" alt="" />
              <div class="left-txt">
                <div class="left-title">物品配送管理</div>
                <p>院内各用药点配送</p>
                <p>配送路线合理化分析</p>
                <p>排班和值班紧贴医院服务要求</p>
                <p>人员配置合理化</p>
              </div>
            </div>
            <div class="content-two-list">
              <img src="../../assets/images/hospital_operate_c3.png" alt="" />
              <div class="left-txt">
                <div class="left-title">物流决策优化</div>
                <p>进行现状问题点分析与改善提案 </p>
                <p>成本分析 、适量采购分析</p>
                <p>保证大库和各消耗点有合适的库存，消灭长期积压库存</p>
                <p>防止结算误差及其分析</p>
              </div>
            </div>
            <div class="content-two-list">
              <img src="../../assets/images/hospital_operate_c4.png" alt="" />
              <div class="left-txt">
                <div class="left-title">定数管理</div>
                <p>定数设置、定数包加工</p>
                <p>定数卡片定期回收</p>
                <p>进行定数及业务流的调整</p>
                <p>部门、品类的消耗管理</p>
                <p>定数与消费实际情况的平衡分析</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-contain">
        <Title titleZh="可视化运营"></Title>
        <img class="w90f" src="../../assets/images/hospital_operate_block3.png" alt="" />
        <div class="h60"></div>
        <img class="w75f" src="../../assets/images/hospital_operate_block4.png" alt="" />
        <div class="h110"></div>
      </div>
    </div>
    <mobilePage></mobilePage>
  </div>
</template>
<script>
import mobilePage from '@/views/hospital/mobile/hospital_operate.vue'; //移动端页面
import Title from '@/components/title/title.vue'; //标题组件
export default {
  components: {
    Title,
    mobilePage
  },
  data() {
    return {
      tabIndex: 0,
      currentIndex: 0,
      tabs: [
        { title: '智能高值耗材柜' },
        { title: '智能普耗柜' },
        { title: '智能配送设备' },
        { title: '智能输送设备' },
      ],
    };
  },
  created() {},
  methods: {
    tabClick(type) {
      this.tabIndex = type;
    },
  },
};
</script>
<style lang="less" scoped>
.home {
  //头部
  .home-header {
    position: relative;
    width: 100%;
    min-height: 450px;
    .bg-text {
      width: 1200px;
      // height: 200px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      .bg-text-left {
        text-align: left;
        .font-title {
          font-size: 59px;
          color: #4fa1a4;
        }
        .font-txt {
          font-size: 24px;
          color: #3d3d3d;
          .font-txt-tag {
            margin: 0 20px;
          }
        }
        .font-intro {
          font-size: 18px;
          color: #3d3d3d;
          text-align: left;
          line-height: 30px;
          margin-top: 70px;
        }
        .next-btn {
          width: 140px;
          height: 40px;
          line-height: 40px;
          font-size: 18px;
          background: #4fa1a4;
          opacity: 1;
          border-radius: 24px;
          text-align: center;
          cursor: pointer;
        }
      }
      .bg-text-right {
        font-family: Source Han Sans CN;
        text-align: right;
        .font-one {
          font-size: 30px;
        }
        .font-two {
          font-size: 22px;
          line-height: 22px;
        }
      }
    }
  }
  //中间
  .main-contain {
    width: 1200px;
    .table-box {
      width: 1080px;
      color: #3d3d3d;
      margin: auto;
      .table-list {
        display: flex;
        align-items: center;
        padding: 14px 0;
        border-bottom: 1px solid #e6e6e6;
        &:first-child {
          border-bottom: none;
          background: #fafafa;
        }
        .table-list-left {
          width: 287px;
          padding-left: 40px;
          flex-shrink: 0;
          text-align: left;
        }
        .table-list-right p {
          font-size: 16px;
          text-align: left;
          line-height: 25px;
        }
      }
    }
  }
  //背景区域
  .bg-wrap {
    padding: 49px 0 147px 0;;
    background-size:100% 100%;
    background-repeat:no-repeat;
    background: url('../../assets/images/hospital_operate_yy_bg.png');
    .bg-box {
      width: 1100px;
      height: 616px;
      margin: auto;
      background: #fff;
      .bg-title-box {
        display: flex;
        background: #fafafa;
        .bg-title-list {
          width: 50%;
          height: 72px;
          line-height: 72px;
          font-size: 20px;
          cursor: pointer;
        }
        .bg-title-list-active {
          color: #4fa1a4;
          background: #fff;
          border-top: 4px solid #4fa1a4;
        }
      }
      //第一部分
      .bg-content-box {
        display: flex;
        align-items: center;
        height: 544px;
        .bg-content-left {
          width: 50%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .content-left-list {
            display: flex;
            margin-bottom: 50px;
            img {
              width: 32px;
              height: 32px;
              margin: 0 20px 0 58px;
            }
            .left-txt {
              text-align: left;
              padding-right: 73px;
              .left-title {
                font-size: 20px;
                margin-bottom: 16px;
              }
              p {
                font-size: 16px;
                line-height: 20px;
              }
            }
          }
        }
        .content-left-img {
          width: 368px;
          margin-left: 40px;
        }
      }
      //第二部分
      .bg-content-two {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        height: 544px;
        .content-two-list {
          width: 50%;
          display: flex;
          img {
            width: 48px;
            height: 48px;
            margin: 0 20px 0 58px;
          }
          .left-txt {
            text-align: left;
            padding-right: 73px;
            .left-title {
              font-size: 20px;
              margin-bottom: 16px;
            }
            p {
              font-size: 16px;
              line-height: 30px;
            }
          }
        }
      }
    }
  }
}
</style>
